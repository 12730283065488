import moment from "moment"
import { saveAs } from "file-saver"
import { USER_ROUTES } from './constants'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = object => {
  if (
    object === null || // Check for null
    object === undefined || // Check for undefined
    typeof object !== 'object' || // Ensure it's an object
    Object.keys(object).length === 0 // Check if it has no keys
  ) {
    return true
  }
  
  return false
}
export const isStringEmpty = str => {
  return str === "" || !str || str === undefined
}
export const isArrayEmpty = array => {
  if (array === undefined || array === "" || array === null || array.length === 0 || !Array.isArray(array)) {
    return true
  } else {
    return false
  }
}

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const isAdminLoggedIn = () => localStorage.getItem('adminData')
export const getAdminData = () => JSON.parse(localStorage.getItem('adminData'))

export const getCurrentUserType = () => {
  const routeChecking = window?.location?.pathname.split('/')
  if (!isArrayEmpty(routeChecking)) {
    return routeChecking[1]
  } else {
    return null
  }
}

export const isCurrentRouteAdmin = () => {
  const routeChecking = getCurrentUserType()
  return ['administration', 'admin'].includes(routeChecking)
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/administration/dashboard'
  // if (userRole === 'clinic') return '/clinic/dashboard'
  if (userRole === 'clinic') return '/clinic/discovery'
  if (userRole === 'patient') return '/patient/discovery'
  if (userRole === 'frontuser') return '/frontuser/AImeditations'
  if (userRole === 'employee') return '/employee/discovery'
  return { name: 'auth-login' }
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export function tableSearchResult(search_array, searchText, object_list) {
  return search_array?.filter(item => {
    const startsWith = object_list?.map(object => String(item[object])?.toLowerCase()?.startsWith(searchText?.toLowerCase()))
    const includes = object_list?.map(object => String(item[object])?.toLowerCase()?.startsWith(searchText?.toLowerCase()))
    if (startsWith.includes(true)) {
      return item
    } else if (!(startsWith?.includes(true)) && includes.includes(true)) {
      return item
    } else return null
  })
}

export const getSearchResult = (data, text) => {
  let originalArray = [...data]
  if (isStringEmpty(text)) {
    return originalArray
  } else {
    text = text.toLowerCase()
    originalArray = originalArray.filter(item => {
      if (
        item?.fname?.toLowerCase().match(text) ||
        item?.lname?.toLowerCase().match(text)
      ) {
        return item
      }
    })
  }
  return originalArray
}

export function formatSelectOptions(array, label, value, addSelect) {
  const finalArray = []
  if (addSelect !== false) {
    finalArray.push({ label: 'Select', value: "" })
  }
  if (isArrayEmpty(array)) {
    return finalArray
  }
  array.map(item => {
    finalArray.push({
      ...item,
      label: item[label],
      value: item[value]
    })
  })
  return finalArray
}

export function getMomentDateFormat(date, format) {
  if (isStringEmpty(format)) {
    format = "MMM DD, YYYY - hh:MM A"
  }
  return moment(date).format(format)
}
export function generateCaptchaID() {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function isValidEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export function getUriFromUrl(order) {
  const pathName = window?.location?.pathname
  const newPathName = pathName.split('/')
  let value = ''
  if (!isArrayEmpty(newPathName)) {
    value = newPathName[newPathName?.length - parseInt(order)]
  }
  return value
}
export function parentRoute() {
  if (isCurrentRouteAdmin() && isAdminLoggedIn()) {
    return `/administration`
  } else if (isUserLoggedIn()) {
    const userData = JSON.parse(isUserLoggedIn())
    return `/${userData?.role}`
  }
  return ''
}
export function downloadFile(fileUrl) {
  saveAs(fileUrl)
}

export const refreshTokenSetup = (res) => {
  // Timing to renew access token
  const refreshTiming = (res.tokenObj?.expires_in || (3600 - 5) * 60) * 1000
  const refreshToken = async () => {
    const newAuthRes = await res?.reloadAuthResponse()
    refreshTiming = (newAuthRes?.expires_in || (3600 - 5) * 60) * 1000
    console.log('newAuthRes:', newAuthRes)
    // saveUserToken(newAuthRes.access_token)  <-- save new token
    localStorage.setItem('authToken', newAuthRes?.id_token)

    // Setup the other timer after the first one
    setTimeout(refreshToken, refreshTiming)
  }
  // Setup first refresh timer
  setTimeout(refreshToken, refreshTiming)
}
export function getUserDummyImage() {
  return require('../assets/images/avatars/avatar-blank.png').default
}
export function getTaskColors() {
  return {
    0: '#fdffb6',
    1: '#caffbf',
    2: '#9bf6ff',
    3: '#a0c4ff',
    4: '#ffc6ff'
  }
}

export function getActionTypeToUpdateLogs() {
  return {
    dashboard: 'not_implement',
    meeting: 'not_implement',
    chat: 'Chat',
    classes: 'Video Classes',
    groups: 'Groups',
    challenges: 'Challenges',
    tasks: 'Tasks',
    assignments: 'Assignments',
    discovery: 'Sections',
    tests: 'Take a Test',
    account: 'Settings',
    favorites: 'Favorites',
    tools: 'Wellness Tools',
    invites: 'Invite People',
    notifications: 'Notifications',
    subscription: 'Subscription'
  }
}
export function getNotificationURL(usertype) {
  let notifRoutes = {
    Appointments: 'meeting',
    'Meeting Session': 'meeting',
    Assignment: 'assignments',
    assignment: 'assignments',
    Post: 'groups',
    Group: 'groups',
    Class: 'meeting',
    'Meeting Session': 'meeting',
    Challenge: 'challenges',
    Content: 'reviews',
    Test: 'tests',
    Task: 'tasks',
    'Clinic Sign up': ''
  }
  if (usertype === USER_ROUTES.admin) {
    notifRoutes = {
      ...notifRoutes,
      Message: 'messages'
    }
  } else if (usertype === USER_ROUTES.clinic) {
    notifRoutes = {
      ...notifRoutes,
      Signup: 'patients'
    }
  } else {
    notifRoutes = {
      ...notifRoutes,
      Message: 'meeting/chat',
      Signup: 'users'
    }
  }
  return notifRoutes
}
export function checkIfObjectEntriesEmpty(obj) {
  for (const key in obj) {
    if (isStringEmpty(obj[key])) return true
  }
  return false
}