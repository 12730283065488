import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { sections } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { getUserData, isArrayEmpty } from '../../../../utility/Utils'
import { displaySuccessToast } from '../../../../utility/helper'

export const setLogs = createAsyncThunk('newDiscovery/setLogs', async () => {
  const response = await axiosRequest.post(sections?.SET_LOGS)
  return { data: response?.data?.data ?? [] }
})


export const setUpdateStoriesVideo = createAsyncThunk('clinicNewSections/setUpdateStoriesVideo', async (data) => {
  console.log('-----onUploadProgress--data---', data)
  return { data }
})
export const setCancelStoriesVideo = createAsyncThunk('clinicNewSections/setCancelStoriesVideo', async (data) => {
  console.log('-----onUploadProgress--data---', data)
  return { data }
})


//
export const getLocalStorgaeData = createAsyncThunk('newDiscovery/getLocalStorgaeData', async () => {
  return { data: getUserData() }
})

export const setLocalStorgaeData = createAsyncThunk('newDiscovery/setLocalStorgaeData', async (data) => {
  return { data }
})

export const getRecentClasses = createAsyncThunk('newDiscovery/getRecentClasses', async () => {
  const response = await axiosRequest.get(sections?.RECENT_CLASSESS)
  return { data: response?.data?.data ?? [] }
})

export const getLessonMeditation = createAsyncThunk('newDiscovery/getLessonMeditation', async (data) => {
  const response = await axiosRequest.post(sections?.GET_LESSON_MEDITATION, data)
  return { data: response?.data?.data ?? [] }
})

export const setLessonMeditation = createAsyncThunk('newDiscovery/setLessonMeditation', async (data) => {
  return { data }
})

export const getLeaveClassResponse = createAsyncThunk('newDiscovery/getLeaveClassResponse', async () => {
  const response = await axiosRequest.get(sections?.LEAVE_CLASS_RESPONSE)
  return { data: response?.data?.data ?? [] }
})

export const setRecentClasses = createAsyncThunk('newDiscovery/setRecentClasses', async (data) => {
  return { data }
})


export const getActiveClasses = createAsyncThunk('newDiscovery/getActiveClasses', async () => {
  const response = await axiosRequest.get(sections?.ACTIVE_CLASSESS)
  console.log("------getActiveClasses-------", response)
  return { data: response?.data?.data ?? [] }
})
export const setActiveClasses = createAsyncThunk('newDiscovery/setActiveClasses', async (data) => {
  return { data }
})
export const updateLogs = createAsyncThunk('newDiscovery/updateLogs', async (params) => {
  for (const pair of params.entries()) {
    console.log('----pair----', pair)
  }
  const response = await axiosRequest.post(sections?.UPDATE_LOG, params)
  console.log('----updateLogs----response---', response)
  return { status: response.data?.status ?? false, message: response.data?.message ?? '' }
})
export const getPopularClasses = createAsyncThunk('newDiscovery/getPopularClasses', async () => {
  const response = await axiosRequest.get(sections?.POPULAR_CLASSESS)
  return { data: response?.data?.data ?? [] }
})
export const getRecentMeditations = createAsyncThunk('newDiscovery/getRecentMeditations', async () => {
  const response = await axiosRequest.post(sections?.RECENT_MEDITAIONS)
  return { data: response?.data?.data ?? [] }
})
export const setRecentMeditations = createAsyncThunk('newDiscovery/setRecentMeditations', async (data) => {
  return { data }
})
export const setPopularClasses = createAsyncThunk('newDiscovery/setPopularClasses', async (data) => {
  return { data }
})
export const setPopularClassesSearch = createAsyncThunk('newDiscovery/setPopularClassesSearch', async (data) => {
  return { data }
})
export const getPopularMeditations = createAsyncThunk('newDiscovery/getPopularMeditations', async () => {
  const response = await axiosRequest.post(sections?.GET_POPULAR_MEDITATIONS)
  return { data: response?.data?.data ?? [] }
})
//
export const setPopularMeditations = createAsyncThunk('newDiscovery/setPopularMeditations', async (data) => {
  return { data }
})
export const setRecommendedMeditationsFront = createAsyncThunk('newDiscovery/setRecommendedMeditationsFront', async (data) => {
  return { data }
})
export const getRecommendedMeditationsFront = createAsyncThunk('newDiscovery/getRecommendedMeditationsFront', async () => {
  const response = await axiosRequest.post(sections?.GET_RECOMMENDED_MEDITATIONS)
  return { data: response?.data?.data ?? [] }
})

export const getAllMeditationsFront = createAsyncThunk('newDiscovery/getAllMeditationsFront', async () => {
  const response = await axiosRequest.post(sections?.GET_MEDITATIONS)
  return { data: response?.data?.data ?? [] }
})
//
export const setAllMeditationsFront = createAsyncThunk('newDiscovery/setAllMeditationsFront', async (data) => {
  return { data }
})

export const getRecommendedMeditation = createAsyncThunk('newDiscovery/getRecommendedMeditation', async () => {
  const response = await axiosRequest.get(sections?.RECOMMENDED_MEDITATION)
  return { data: response?.data?.data ?? [] }
})
// watch time 
export const watchTime = createAsyncThunk('welcomeScreen/watchTime', async (data) => {
  console.log('------data----watchTIme----', data)
  const response = await axiosRequest.post(sections?.LISTEN_TIME, data)
  console.log('------response----watchTIme----', response)
  return { data: response?.data?.data, duration: response.data?.time_listen_duration }
})
export const watchTimeAll = createAsyncThunk('welcomeScreen/watchTimeAll', async (data) => {
  console.log('------data----watchTIme----', data)
  const response = await axiosRequest.post(sections?.LISTEN_TIME, data)
  console.log('------response----watchTIme----', response)
  return { data: response?.data?.data, duration: response.data?.time_listen_duration }
})
//
export const countAudio = createAsyncThunk('newDiscovery/countAudio', async (data) => {
  console.log('------data----COUNT_AUDIO----', data)
  const response = await axiosRequest.post(sections?.COUNT_AUDIO, data)
  console.log('------response----COUNT_AUDIO----', response)
  return { data: response?.data?.data, duration: response.data }
})
//
export const getListenTimeAudio = createAsyncThunk('newDiscovery/getListenTimeAudio', async (data) => {
  const response = await axiosRequest.post(sections?.GET_LISTEN_TIME, data)
  return { data: response?.data?.data, id: response.data ?? [] }
})
//
export const getRecommendedClasses = createAsyncThunk('newDiscovery/getRecommendedClasses', async () => {
  const response = await axiosRequest.get(sections?.RECOMMENDED_CLASSESS)
  return { data: response?.data?.data ?? [] }
})
export const setRecommendedClasses = createAsyncThunk('newDiscovery/setRecommendedClasses', async (data) => {
  return { data }
})
export const getSectionContentAndClasses = createAsyncThunk('frontSections/getSectionContentAndClasses', async (id) => {
  const response = await axiosRequest.post(sections.FRONT_SECTION_CONTENT_AND_CLASSES, { id })
  return { data: response.data?.data ?? {} }
})

export const getsignature = createAsyncThunk('frontSections/getsignature', async (user_sign) => {
  const response = await axiosRequest.post(sections.SIGNATURE, { user_sign })
  return { data: response.data?.data ?? {} }
})

export const postquestions = createAsyncThunk('frontSections/postquestions', async (question_data) => {
  const response = await axiosRequest.post(sections.BUSINESS_QUESTIONS, question_data)
  return { data: response.data?.data ?? {} }
})
export const getMaintainanceStatus = createAsyncThunk('auth/getMaintainanceStatus', async () => {
  const response = await axiosRequest.get(sections.GET_MAINTAINANCE_STATUS)
  console.log("======getMaintainanceStatus=======", response?.data?.status)
  return { status: response ?? [] }
})
export const getCompleteMeditation = createAsyncThunk('frontSections/getCompleteMeditation', async (meditation_id) => {
  const response = await axiosRequest.post(sections.COMPLETE_MEDITATION, { meditation_id })
  return { data: response.data?.data ?? {} }
})

export const chatGptText = createAsyncThunk('frontSections/chatGptText', async (data) => {
  const response = await axiosRequest.post(sections.CHATGPT_TEXT, data)
  return { data: response?.data?.data ?? {} }
})


export const chatGptVoice = createAsyncThunk('frontSections/chatGptVoice', async (prompt) => {
  const response = await axiosRequest.post(sections.CHATGPT_VOICE, prompt)
  return { data: response?.data?.data ?? {} }
})

export const chatGptHistory = createAsyncThunk('frontSections/chatGptHistory', async () => {
  const response = await axiosRequest.get(sections.CHATGPT_HISTORY)
  return { data: response?.data?.data ?? {} }
})

export const chatGptHistoryContent = createAsyncThunk('frontSections/chatGptHistoryContent', async (id) => {
  const response = await axiosRequest.post(sections.CHATGPT_HISTORY, {id})
  return { data: response?.data?.data ?? {} }
})

export const sendNotify = createAsyncThunk('frontSections/sendNotify', async (class_id) => {
  const response = await axiosRequest.post(sections.NOTIFY, { class_id })
  return { data: response.data?.data ?? {} }
})
export const completeClassDetail = createAsyncThunk('frontSections/completeClassDetail', async (class_id) => {
  const response = await axiosRequest.post(sections.COMPLETE_CLASS, { class_id })
  return { data: response.data?.data ?? {} }
})
export const classAddToWatch = createAsyncThunk('frontSections/classAddToWatch', async (data) => {
  const response = await axiosRequest.post(sections?.ADD_TO_WATCH, data)
  return { data: response.data?.data ?? {} }
})
export const feedBackValue = createAsyncThunk('frontSections/feedBackValue', async (data) => {
  const response = await axiosRequest.post(sections?.FEED_BACK_VALUE, data)
  return { data: response ?? {} }
})
export const getWatchListData = createAsyncThunk('frontSections/getWatchListData', async (data) => {
  const response = await axiosRequest.post(sections?.GET_WATCH_LIST_DATA, data)
  return { data: response.data?.data ?? {} }
})

export const watchListMarkComplete = createAsyncThunk('frontSections/watchListMarkComplete', async (data) => {
  const response = await axiosRequest.post(sections?.CHECK_WATCHLIST_COMPLETE, data)
  return { data: response.data?.data ?? {} }
})
export const watchListRemove = createAsyncThunk('frontSections/watchListRemove', async (data) => {
  const response = await axiosRequest.post(sections?.REMOVE_WATCH_LIST, data)
  return { data: response.data?.data ?? {} }
})

//
export const setCompletemeditation = createAsyncThunk('newDiscovery/setCompletemeditation', async (data) => {
  return { data }
})
export const getClassLessonDetail = createAsyncThunk('frontSections/getClassLessonDetail', async (class_id) => {
  const response = await axiosRequest.post(sections.CLASS_DETAIL, { class_id })
  console.log('---------getClassLessonDetail-----response=---', response?.data?.data)
  return { data: response.data?.data ?? [] }
})

export const getClassResourseDetail = createAsyncThunk('frontSections/getClassResourseDetail', async (class_id) => {
  const response = await axiosRequest.post(sections.RESOURSE_DETAIL, { class_id })
  return { data: response.data?.data ?? {} }
})

export const getLessonContentseDetail = createAsyncThunk('frontSections/getLessonContentseDetail', async (content_id) => {
  const response = await axiosRequest.post(sections.LESSON_CONTENT_DETAIL, { content_id })
  return { data: response.data?.data ?? {} }
})

export const getLessonsAndContents = createAsyncThunk('frontSections/getLessonsAndContents', async (class_id) => {
  const response = await axiosRequest.post(sections.GET_LESSON_CONTENT, { class_id })
  return { data: response.data?.data ?? [] }
})

export const getMeditationTask = createAsyncThunk('frontSections/getMeditationTask', async (data_id) => {
  const response = await axiosRequest.post(sections.MEDITATION_TASKS, { data_id })
  console.log('----getMeditationTask----response---', response?.data?.data)
  return { data: response.data?.data?.tasks ?? {} }
})
export const getMeditationMoreTask = createAsyncThunk('frontSections/getMeditationMoreTask', async (data) => {
  const response = await axiosRequest.post(sections.MEDITATION_MORE_TASKS, data)
  console.log('----getMeditationMoreTask----response---', response?.data?.data)
  return { data: response.data?.data ?? [] }
})

export const setUpdatdTasks = createAsyncThunk('frontSections/setUpdatdTasks', async (data) => {
  return { data }
})
export const getMeditaionDoneTask = createAsyncThunk('frontSections/getMeditaionDoneTask', async (id) => {
  const response = await axiosRequest.post(sections.MARK_TASK, { id })
  return { status: response?.data?.status ?? {} }
})
export const getDoneTask = createAsyncThunk('frontSections/getDoneTask', async (id) => {
  const response = await axiosRequest.post(sections.MARK_TASK, id)
  return { status: response?.data?.status ?? {} }
})

export const delStories = createAsyncThunk('frontSections/delStories', async (id) => {
  const response = await axiosRequest.post(sections.DEL_STORIES, { id })
  console.log("----------delstories---", response?.data)
  return { status: response?.data?.status ?? {} }
})

// Create an instance of AbortController
const abortController = new AbortController()

// Create an AbortSignal to pass to the request
const abortSignal = abortController.signal

// Function to cancel the request
export const cancelRequest = createAsyncThunk('frontSections/delStories', async () => {

  const response = await abortController.abort()
  return response
})

export const addStories = createAsyncThunk('frontSections/addStories', async (data, { dispatch }) => {
  try {
    const response = await axiosRequest.post(sections.ADD_STORIES, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (data) => {
        dispatch(setUpdateStoriesVideo(Math.round(100 * (data.loaded / data.total))))
      },
      signal: abortSignal // Pass the signal to the request
    }
    )
    return { status: response?.data?.status }
  } catch (error) {
    // Handle the error or dispatch an action accordingly
  }
}
)

export const updateStories = createAsyncThunk('frontSections/updateStories', async (data, { dispatch }) => {
  const response = await axiosRequest.post(sections.UPDATE_STORIES, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: (data) => {
      dispatch(setUpdateStoriesVideo(Math.round(100 * (data.loaded / data.total))))
    }
  })
  return { status: response?.data?.status }
})

export const getCategoriesClasses = createAsyncThunk('frontSections/getCategoriesClasses', async (data) => {
  const response = await axiosRequest.post(sections.GET_CATEGORIES, data)
  return { data: response.data?.data ?? [] }
})
export const setCategoriesClasses = createAsyncThunk('newDiscovery/setCategoriesClasses', async (data) => {
  return { data }
})

export const getCategoriesMeditations = createAsyncThunk('frontSections/getCategoriesMeditations', async (data) => {
  console.log("data123", data)
  const response = await axiosRequest.post(sections.GET_CATEGORIES, data)
  return { data: response.data?.data ?? [] }
})
export const setCategoriesMeditations = createAsyncThunk('newDiscovery/setCategoriesMeditations', async (data) => {
  return { data }
})

export const getCategoriesByClass = createAsyncThunk('frontSections/getCategoriesByClass', async (cat_id) => {
  const response = await axiosRequest.post(sections.GET_CATEGORIES_BY_CLASS, { cat_id })
  console.log("======response data getCategoriesByClass", response.data)

  return { data: response.data?.data ?? {} }
})
export const updateCategoriesMeditations = createAsyncThunk('frontSections/updateCategoriesMeditations', async (data) => {
  return { data }
})

export const leaveClass = createAsyncThunk('frontSections/leaveClass', async (data) => {
  const response = await axiosRequest.post(sections.LEAVE_CLASS, data)
  return { data: response.data?.data ?? {} }
})


export const getCategoriesByMeditations = createAsyncThunk('frontSections/getCategoriesByMeditations', async (cat_id) => {
  const response = await axiosRequest.post(sections.GET_CATEGORIES_BY_MEDITATIONS, { cat_id })
  console.log("======response data", response.data)

  return { data: response.data?.data ?? {} }
})
export const setCategoriesByMeditations = createAsyncThunk('newDiscovery/setCategoriesByMeditations', async (data) => {
  return { data }
})
export const getALLRecentClasses = createAsyncThunk('frontSections/getALLRecentClasses', async () => {
  const response = await axiosRequest.get(sections.GET_ALL_RECENT_CLASSES)
  return { data: response.data?.data ?? {} }
})
export const updateRecentAllClasses = createAsyncThunk('newDiscovery/updateRecentAllClasses', async (data) => {
  return { data }
})

export const getStories = createAsyncThunk('frontSections/getStories', async (class_id) => {
  const response = await axiosRequest.post(sections.GET_STORIES, { class_id })
  console.log("--getStories--", response)
  return { data: response.data?.data ?? {} }
})

export const getSectionCategories = createAsyncThunk('frontSections/getSectionCategories', async (id) => {
  const response = await axiosRequest.post(sections.FRONT_SECTION_CATEGORIES, { id })
  return { data: response.data?.data ?? [] }
})

export const getCategoryContent = createAsyncThunk('frontSections/getCategoryContent', async (id) => {
  const response = await axiosRequest.post(sections.FRONT_SECTION_CATEGORY_CONTENT, { id })
  return { data: response.data?.data ?? [], detail: response?.data?.data?.title ?? '' }
})

export const favouriteaAndUnfavourite = createAsyncThunk('frontSections/favouriteaAndUnfavourite', async (data) => {
  console.log("=========favouriteaAndUnfavourite======data", data)
  const response = await axiosRequest.post(sections.FAVOURITE_CLASSES, data)
  console.log("response", response)
  return { data: response.data?.data ?? {} }
})

export const getClassContentLessons = createAsyncThunk('frontSections/getClassContentLessons', async (id) => {
  const response = await axiosRequest.post(sections.FRONT_SECTION_CLASS_CONTENT_AND_LESSONS, { id })
  return {
    data: response.data?.data ?? {},
    classInfo: response?.data?.detail ?? {},
    tasks: response?.data?.tasks ?? []
  }
})

export const getLessonContent = createAsyncThunk('frontSections/getLessonContent', async (id) => {
  const response = await axiosRequest.post(sections.FRONT_SECTION_LESSON_CONTENT, { id })
  return {
    data: response.data?.data ?? [],
    lessonDetail: response.data?.detail ?? {},
    tasks: response?.data?.tasks ?? []
  }
})

export const getContentDetail = createAsyncThunk('frontSections/getContentDetail', async (id) => {
  const response = await axiosRequest.post(sections.FRONT_SECTION_CONTENT_DETAIL, { id })
  const responseData = response?.data
  let data = {}
  if (!isArrayEmpty(response?.data?.data)) {
    data = responseData?.data[0] ?? {}
  }
  data.tasks = responseData?.tasks
  return { data, status: responseData?.status }
})
export const setContentDetail = createAsyncThunk('frontSections/setContentDetail', async (data) => {
  return { data }
})

export const getCompleteLessonContent = createAsyncThunk('frontSections/getCompleteLessonContent', async (data) => {
  console.log("-------getCompleteLessonContent-------", data)
  const response = await axiosRequest.post(sections?.GET_LESSONS_COMPLETE_CONTENTS, data)
  return { data: response?.data?.data ?? [] }
})

// GET LESSON TASKS
export const getLessonsTasks = createAsyncThunk('frontSections/getLessonsTasks', async (data) => {
  const response = await axiosRequest.post(sections?.GET_LESSONS_TASKS, data)
  return { data: response?.data?.data?.tasks, id: response.data.next_content_id ?? [] }
})

// JOIN A CLASS
export const joinAClass = createAsyncThunk('frontSections/joinAClass', async (data) => {
  const response = await axiosRequest.post(sections?.JOIN_A_CLASS, data)
  return { status: response?.data?.status ?? [] }
})

// GET LESSON TASKS
export const setFrontLessonsTasks = createAsyncThunk('frontSections/setFrontLessonsTasks', async (data) => {
  return { data }
})

// SET LESSON CONTENTS
export const setLessonContents = createAsyncThunk('frontSections/setLessonContents', async (data) => {
  return { data }
})

// GET LESSON TASKS
export const setClassDetail = createAsyncThunk('frontSections/setClassDetail', async (data) => {
  return { data }
})

// COMPLETE TASK
export const completeTasks = createAsyncThunk('frontSections/completeTasks', async (data) => {
  const response = await axiosRequest.post(sections?.CREATE_TASKS, data)
  return { status: response?.data?.status ?? [] }
})

// SET MEDITATION INDEX
export const setMeditationIndex = createAsyncThunk('frontSections/setMeditationIndex', async (data) => {
  return { data }
})
export const updateWatchTime = createAsyncThunk('frontSections/updateWatchTime', async (data) => {
  console.log('---------updateWatchTime-----data=---', data)
  const response = await axiosRequest.post(sections.UPDATE_WATCH_TIME, data)
  console.log('---------updateWatchTime-----response=---', response?.data)
  return { data: response.data?.data ?? [] }
})

export const getVideoWatchTime = createAsyncThunk('frontSections/getVideoWatchTime', async (data) => {
  console.log('---------getVideoWatchTime-----data=---', data)
  const response = await axiosRequest.post(sections.GET_WATCHED_TIME, data)
  console.log('---------getVideoWatchTime-----response=---', response?.data)
  return { data: response.data?.data ?? [] }
})

export const setGoBackToggle = createAsyncThunk('frontSections/setGoBackToggle', async (data) => {
  return { data }
})
// GET ALL SHARED CLASSESS
export const getAllSharedClasses = createAsyncThunk('clinicNewSections/getAllSharedClasses', async () => {
  const response = await axiosRequest.get(sections?.GET_ALL_SHARED_CLASSES)
  return { data: response?.data?.data ?? [] }
})

export const newDiscoverySlice = createSlice({
  name: 'newDiscovery',
  initialState: {
    allSharedClasses: [],
    recentLoading: false,
    voiceLoading: false,
    textLoading: false,
    lessonMeditationLoader: true,
    lessonMeditation: [],
    recentClasses: [],
    activeClasses: [],
    popularClasses: [],
    updateStoriesVideoProgress: false,
    storyProgressupdate: " ",
    favAndUnfav: [],
    feedBackData: [],
    recommendedMeditation: [],
    recommendedClasses: [],
    completemeditation: [],
    deleteStories: [],
    addStoriesData: [],
    updateStoriesData: [],
    popularMeditationsFront: [],
    recommendedMeditationsFront: [],
    getAllMeditations: [],
    TaskUpdateLoading: true,
    classLessonDetail: [],
    voicehistoryLoading: false,
    classLessonDetailLoader: true,
    historyContentChatgptStatus: false,
    textChatGpt: [],
    historyContentChatgpt: [],
    historyChatgpt: [],
    VoiceChatgpt: [],
    meditationTask: [],
    markTask: [],
    watchListRemove: [],
    categoriesClasses: [],
    recentMeditations: [],
    getcategoriesByClasses: [],
    categoriesByMeditations: [],
    getStoriesData: [],
    countAudiofile: [],
    getRecentAllClasses: [],
    notify: [],
    mostWatchedVideos: [],
    classResourceDetail: [],
    leaveClasses: [],
    lessonContentDetail: [],
    maintanceData: [],
    maintanceLoading: false,
    popularMeditations: [],
    sectionDetailLoading: false,
    sectionDetail: {},
    sectionCategories: [],
    categoryDetail: '',
    categoryContents: [],
    classInfo: {},
    classDetail: {},
    lessonContent: [],
    lessonDetail: {},
    contentDetail: {},
    frontCompleteLessonContent: [],
    frontCompleteContentLoader: true,
    watchList: [],
    watchListData: [],
    watchListComplete: [],
    completeClassFront: [],
    categoriesMeditations: [],
    frontLessonsTasks: [],
    signature: [],
    questions: [],
    watchTimeCal: [],
    leaveClassResponse: [],
    nextLessonId: "",
    lessonTasksLoading: true,
    updateWatchTimeAll: [],
    listenTimeAudio: [],
    lessonsAndContents: [],
    categoriesClassesLoading: true,
    categoriesMeditationLoading: true,
    getChatGptHistory: false,
    lessonContents: [],
    videoWatchedTime: '',
    meditationIndex: 0,
    toggleValue: "1",
    lessonID: '',
    meditationMoreTask: [],
    localStorageDateOfUser: [],
    time_listen_duration: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getLocalStorgaeData.fulfilled, (state, { payload }) => {
        console.log('----localStorageDateOfUser----', payload?.data)
        state.localStorageDateOfUser = payload.data
      })
      .addCase(setLocalStorgaeData.fulfilled, (state, { payload }) => {
        console.log('----setLocalStorgaeData----', payload?.data)
        state.localStorageDateOfUser = payload.data
      })
      //GET POPULAR CLASSESS
      .addCase(getPopularClasses.fulfilled, (state, { payload }) => {
        state.recentLoading = false
        state.popularClasses = payload.data
      })
      .addCase(getPopularClasses.pending, (state) => {
        state.recentLoading = true
      })
      .addCase(getPopularClasses.rejected, (state) => {
        state.recentLoading = false
      })
      //
      .addCase(getRecentMeditations.fulfilled, (state, { payload }) => {
        state.recentLoading = false
        state.recentMeditations = payload.data
      })
      .addCase(getRecentMeditations.pending, (state) => {
        state.recentLoading = true
      })
      .addCase(getRecentMeditations.rejected, (state) => {
        state.recentLoading = false
      })
      .addCase(setRecentMeditations.fulfilled, (state, { payload }) => {
        state.recentMeditations = payload.data
      })

      //
      .addCase(setPopularClasses.fulfilled, (state, { payload }) => {
        state.popularClasses = payload.data
      })
      .addCase(setPopularClassesSearch.fulfilled, (state, { payload }) => {
        state.popularClasses = payload.data
      })
      //

      .addCase(getRecentClasses.fulfilled, (state, { payload }) => {
        state.recentLoading = false
        state.recentClasses = payload.data
      })
      .addCase(getRecentClasses.pending, (state) => {
        state.recentLoading = true
      })
      .addCase(getRecentClasses.rejected, (state) => {
        state.recentLoading = false
      })

      //

      .addCase(feedBackValue.fulfilled, (state, { payload }) => {
        state.recentLoading = false
        state.feedBackData = payload.data
      })
      .addCase(feedBackValue.pending, (state) => {
        state.recentLoading = true
      })
      .addCase(feedBackValue.rejected, (state) => {
        state.recentLoading = false
      })

      //
      .addCase(getLessonMeditation.fulfilled, (state, { payload }) => {
        state.recentLoading = false
        state.lessonMeditation = payload.data
      })
      .addCase(getLessonMeditation.pending, (state) => {
        state.lessonMeditationLoader = true
      })
      .addCase(getLessonMeditation.rejected, (state) => {
        state.lessonMeditationLoader = false
      })
      //
      .addCase(setLessonMeditation.fulfilled, (state, { payload }) => {
        state.recentLoading = false
        state.lessonMeditation = payload.data
      })
      .addCase(setLessonMeditation.pending, (state) => {
        state.lessonMeditationLoader = true
      })
      .addCase(setLessonMeditation.rejected, (state) => {
        state.lessonMeditationLoader = false
      })
      //
      .addCase(setRecentClasses.fulfilled, (state, { payload }) => {
        state.recentClasses = payload.data
      })
      //
      .addCase(getLeaveClassResponse.fulfilled, (state, { payload }) => {
        state.recentLoading = false
        state.leaveClassResponse = payload.data
      })
      .addCase(getLeaveClassResponse.pending, (state) => {
        state.recentLoading = true
      })
      .addCase(getLeaveClassResponse.rejected, (state) => {
        state.recentLoading = false
      })
      //
      .addCase(setRecommendedClasses.fulfilled, (state, { payload }) => {
        state.recommendedClasses = payload.data
      })
      // GET ACTIVE CLASS
      .addCase(getActiveClasses.fulfilled, (state, { payload }) => {
        state.recentLoading = false
        state.activeClasses = payload.data
      })
      .addCase(getActiveClasses.pending, (state) => {
        state.recentLoading = true
      })
      .addCase(getActiveClasses.rejected, (state) => {
        state.recentLoading = false
      })
      //
      .addCase(setActiveClasses.fulfilled, (state, { payload }) => {
        state.activeClasses = payload.data
      })
      //
      // .addCase(updateStoriesVideo.fulfilled, (state, { payload }) => {
      //   if (payload?.status) {
      //     displaySuccessToast('video Updated Successfully')
      //   }
      // })
      .addCase(setUpdateStoriesVideo.fulfilled, (state, { payload }) => {
        state.storyProgressupdate = payload?.data ?? []

        state.updateStoriesVideoProgress = true
        if (payload?.data === 100) {
          state.updateStoriesVideoProgress = false
        }
      })
      //
      .addCase(setCancelStoriesVideo.fulfilled, (state, { payload }) => {
        state.storyProgressupdate = payload?.data ?? []

        state.updateStoriesVideoProgress = false
      })


      //
      .addCase(setPopularMeditations.fulfilled, (state, { payload }) => {
        state.popularMeditationsFront = payload.data
      })
      .addCase(setRecommendedMeditationsFront.fulfilled, (state, { payload }) => {
        state.recommendedMeditationsFront = payload.data
      })
      //
      .addCase(setAllMeditationsFront.fulfilled, (state, { payload }) => {
        state.getAllMeditations = payload.data
      })
      //
      .addCase(getSectionContentAndClasses.fulfilled, (state, { payload }) => {
        state.sectionDetail = payload?.data
        state.sectionDetailLoading = false
      })
      .addCase(getSectionContentAndClasses.pending, (state) => {
        state.sectionDetailLoading = true
      })
      .addCase(getSectionContentAndClasses.rejected, (state) => {
        state.sectionDetailLoading = false
      })
      //
      .addCase(getsignature.fulfilled, (state, { payload }) => {
        state.signature = payload?.data

      })
      //
      .addCase(sendNotify.fulfilled, (state, { payload }) => {
        state.notify = payload?.data

      })
      //
      .addCase(completeClassDetail.fulfilled, (state, { payload }) => {
        state.completeClassFront = payload?.data

      })
      .addCase(classAddToWatch.fulfilled, (state, { payload }) => {
        state.watchList = payload?.data

      })

      .addCase(getWatchListData.fulfilled, (state, { payload }) => {
        state.watchListData = payload?.data

      })

      .addCase(watchListMarkComplete.fulfilled, (state, { payload }) => {
        state.watchListComplete = payload?.data

      })
      .addCase(watchListRemove.fulfilled, (state, { payload }) => {
        state.watchListRemove = payload?.data

      })
//
.addCase(chatGptText.fulfilled, (state, { payload }) => {
  state.textChatGpt = payload?.data
  state.textLoading = false
})
.addCase(chatGptText.pending, (state) => {
  state.textLoading = true
})
.addCase(chatGptText.rejected, (state) => {
  state.textLoading = false
})
.addCase(chatGptVoice.fulfilled, (state, { payload }) => {
  state.VoiceChatgpt = payload?.data
  state.voiceLoading = false
})
.addCase(chatGptVoice.pending, (state) => {
  state.voiceLoading = true
})
.addCase(chatGptVoice.rejected, (state) => {
  state.voiceLoading = false
})
.addCase(chatGptHistory.fulfilled, (state, { payload }) => {
  state.historyChatgpt = payload?.data
  state.voicehistoryLoading = false
})
.addCase(chatGptHistory.pending, (state) => {
  state.voicehistoryLoading = true
})
.addCase(chatGptHistory.rejected, (state) => {
  state.voicehistoryLoading = false
})
.addCase(chatGptHistoryContent.fulfilled, (state, { payload }) => {
  state.historyContentChatgpt = payload?.data
  state.getChatGptHistory = false
})
.addCase(chatGptHistoryContent.pending, (state) => {
  state.getChatGptHistory = true
})
.addCase(chatGptHistoryContent.rejected, (state) => {
  state.getChatGptHistory = false
})

      //GET COMPLETE MEDITATION
      .addCase(getCompleteMeditation.fulfilled, (state, { payload }) => {
        state.completemeditation = payload?.data
        state.recentLoading = false
      })
      .addCase(getCompleteMeditation.pending, (state) => {
        state.recentLoading = true
      })
      .addCase(getCompleteMeditation.rejected, (state) => {
        state.recentLoading = false
      })
      //
      .addCase(setCompletemeditation.fulfilled, (state, { payload }) => {
        state.completemeditation = payload.data
      })
      //GET CLASS DETAIL
      .addCase(getClassLessonDetail.fulfilled, (state, { payload }) => {
        state.classLessonDetail = payload?.data ?? []
        state.classLessonDetailLoader = false
      })
      .addCase(getClassLessonDetail.pending, (state) => {
        state.classLessonDetailLoader = true
      })
      .addCase(getClassLessonDetail.rejected, (state) => {
        state.classLessonDetailLoader = false
      })

      //GET CLASS RESOURCE
      .addCase(getClassResourseDetail.fulfilled, (state, { payload }) => {
        state.classResourceDetail = payload?.data
        state.recentLoading = false
      })

      //GET Mark TASK
      .addCase(getDoneTask.fulfilled, (state, { payload }) => {
        // state.markTask = payload?.data
        if (payload?.status) {
          displaySuccessToast('Task has been Done Successfully')
        }
        // state.recentLoading = false
      })
      .addCase(getMeditaionDoneTask.fulfilled, (state, { payload }) => {
        // state.markTask = payload?.data
        if (payload?.status) {
          displaySuccessToast('Task has been Done Successfully')
        }
        // state.recentLoading = false
      })

      //GET DEL STORIES
      .addCase(delStories.fulfilled, (state, { payload }) => {
        state.deleteStories = payload?.data
        state.recentLoading = false
      })

      //GET ADD STORIES
      .addCase(addStories.fulfilled, (state, { payload }) => {
        state.addStoriesData = payload.data
        state.recentLoading = false
      })
      .addCase(updateStories.fulfilled, (state, { payload }) => {
        state.addStoriesData = payload.data
        state.recentLoading = false
      })

      //GET CLASS LESSON CONTENT
      .addCase(getLessonContentseDetail.fulfilled, (state, { payload }) => {
        state.lessonContentDetail = payload?.data
        state.recentLoading = false
      })

      .addCase(getLessonsAndContents.fulfilled, (state, { payload }) => {
        state.lessonsAndContents = payload?.data
      })
      //GET MEDITATION TASK
      .addCase(getMeditationTask.fulfilled, (state, { payload }) => {
        state.meditationTask = payload?.data
        state.TaskUpdateLoading = false
      })
      .addCase(setUpdatdTasks.pending, (state) => {
        state.TaskUpdateLoading = true
      })
      .addCase(setUpdatdTasks.rejected, (state) => {
        state.TaskUpdateLoading = false
      })
      //GET MEDITATION MORE TASK
      .addCase(getMeditationMoreTask.fulfilled, (state, { payload }) => {
        state.meditationMoreTask = payload?.data
        // state.recentLoading = false
      })

      .addCase(favouriteaAndUnfavourite.fulfilled, (state, { payload }) => {
        console.log("payload", payload?.data)
        state.favAndUnfav = payload?.data
      })

      .addCase(setUpdatdTasks.fulfilled, (state, { payload }) => {
        state.meditationTask = payload?.data
      })
      

      //GET CATEGORIES CLASSES
      .addCase(getCategoriesClasses.fulfilled, (state, { payload }) => {
        console.log('----getCategoriesClasses.fulfilled----', payload?.data)
        state.categoriesClasses = payload?.data ?? []
        state.categoriesClassesLoading = false
      })
      .addCase(getCategoriesClasses.pending, (state) => {
        console.log('----getCategoriesClasses.pending----')
        state.categoriesClassesLoading = true
      })
      .addCase(getCategoriesClasses.rejected, (state) => {
        console.log('----getCategoriesClasses.rejected----')
        state.categoriesClassesLoading = false
      })
      //
      .addCase(setCategoriesClasses.fulfilled, (state, { payload }) => {
        state.categoriesClasses = payload.data
      })
      //GET CATEGORIES CLASSES
      .addCase(getCategoriesMeditations.fulfilled, (state, { payload }) => {
        console.log('----getCategoriesClasses.fulfilled----', payload?.data)
        state.categoriesMeditations = payload?.data ?? []
        state.categoriesMeditationLoading = false
      })
      .addCase(getCategoriesMeditations.pending, (state) => {
        console.log('----getCategoriesClasses.pending----')
        state.categoriesMeditationLoading = true
      })
      .addCase(getCategoriesMeditations.rejected, (state) => {
        console.log('----getCategoriesClasses.rejected----')
        state.categoriesMeditationLoading = false
      })
      .addCase(setCategoriesMeditations.fulfilled, (state, { payload }) => {
        state.categoriesMeditations = payload.data
      })
      //GET CATEGORIES BY CLASSES
      .addCase(getCategoriesByClass.fulfilled, (state, { payload }) => {
        state.getcategoriesByClasses = payload?.data
        state.recentLoading = false
      })
      //updateCategoriesMeditations
      .addCase(updateCategoriesMeditations.fulfilled, (state, { payload }) => {
        state.getcategoriesByClasses = payload?.data
        state.recentLoading = false
      })
      //
      .addCase(leaveClass.fulfilled, (state, { payload }) => {
        state.leaveClasses = payload?.data
        state.recentLoading = false
      })
      ///
      .addCase(getCategoriesByMeditations.fulfilled, (state, { payload }) => {
        state.categoriesByMeditations = payload?.data
        state.recentLoading = false
      })
      ///

      .addCase(setCategoriesByMeditations.fulfilled, (state, { payload }) => {
        state.categoriesByMeditations = payload?.data
        state.recentLoading = false
      })
      ///
      .addCase(getALLRecentClasses.fulfilled, (state, { payload }) => {
        state.getRecentAllClasses = payload?.data
        state.recentLoading = false
      })
      //
      .addCase(updateRecentAllClasses.fulfilled, (state, { payload }) => {
        state.getRecentAllClasses = payload?.data
        state.recentLoading = false
      })
      //GET STORIES
      .addCase(getStories.fulfilled, (state, { payload }) => {
        state.getStoriesData = payload.data
        state.recentLoading = false
      })

      //GET SECTION CATAGORIES
      .addCase(getSectionCategories.fulfilled, (state, { payload }) => {
        state.sectionCategories = payload.data
      })

      .addCase(updateLogs.fulfilled, ({ payload }) => {
        console.log(payload?.status)
      })

      //getPopularMeditations
      .addCase(getPopularMeditations.fulfilled, (state, { payload }) => {
        state.recentLoading = false
        state.popularMeditationsFront = payload.data
      })
      .addCase(getPopularMeditations.pending, (state) => {
        state.recentLoading = true
      })
      .addCase(getPopularMeditations.rejected, (state) => {
        state.recentLoading = false
      })
      //getAllMeditationsFront
      .addCase(getAllMeditationsFront.fulfilled, (state, { payload }) => {
        state.recentLoading = false
        state.getAllMeditations = payload.data
      })
      .addCase(getAllMeditationsFront.pending, (state) => {
        state.recentLoading = true
      })
      .addCase(getAllMeditationsFront.rejected, (state) => {
        state.recentLoading = false
      })//Watch time
      .addCase(watchTime.fulfilled, (state, { payload }) => {
        state.updateWelcomeIntro = payload?.data
        state.time_listen_duration = payload?.duration

        state.watchTimeCal = false
      })
      .addCase(watchTime.pending, (state) => {
        state.watchTimeCal = true
      })
      .addCase(watchTime.rejected, (state) => {
        state.recentLoading = false
      })
      //
      .addCase(watchTimeAll.fulfilled, (state, { payload }) => {
        state.updateWatchTimeAll = payload?.data
      })
      .addCase(watchTimeAll.pending, (state) => {
        state.watchTimeCal = true
      })
      .addCase(watchTimeAll.rejected, (state) => {
        state.recentLoading = false
      })
      //
      .addCase(countAudio.fulfilled, (state, { payload }) => {
        state.countAudiofile = payload?.data
        state.watchTimeCal = false
      })
      .addCase(countAudio.pending, (state) => {
        state.watchTimeCal = true
      })
      .addCase(countAudio.rejected, (state) => {
        state.recentLoading = false
      })
      //
      .addCase(getListenTimeAudio.fulfilled, (state, { payload }) => {
        state.updateWelcomeIntro = payload?.data
        state.listenTimeAudio = false
      })
      .addCase(getListenTimeAudio.pending, (state) => {
        state.listenTimeAudio = true
      })
      .addCase(getListenTimeAudio.rejected, (state) => {
        state.recentLoading = false
      })
      //
      .addCase(getMaintainanceStatus.fulfilled, (state, { payload }) => {
        state.maintanceData = payload.status
        state.maintanceLoading = false
        console.log("--------getMaintainanceStatus-----", payload?.status)
      })
      .addCase(getMaintainanceStatus.pending, (state) => {
        state.maintanceLoading = true
      })
      .addCase(getMaintainanceStatus.rejected, (state) => {
        state.maintanceLoading = false
      })
      //GET RECOMMENDED MEDITATION
      .addCase(getRecommendedMeditation.fulfilled, (state, { payload }) => {
        state.recentLoading = false
        state.recommendedMeditation = payload.data
      })
      .addCase(getRecommendedMeditation.pending, (state) => {
        state.recentLoading = true
      })
      .addCase(getRecommendedMeditation.rejected, (state) => {
        state.recentLoading = false
      })
      //getRecommendedMeditationsFront
      .addCase(getRecommendedMeditationsFront.fulfilled, (state, { payload }) => {
        state.recentLoading = false
        state.recommendedMeditationsFront = payload.data
      })
      .addCase(getRecommendedMeditationsFront.pending, (state) => {
        state.recentLoading = true
      })
      .addCase(getRecommendedMeditationsFront.rejected, (state) => {
        state.recentLoading = false
      })

      //GET RECOMMENDED CLASSES
      .addCase(getRecommendedClasses.fulfilled, (state, { payload }) => {
        state.recentLoading = false
        state.recommendedClasses = payload.data
      })
      .addCase(getRecommendedClasses.pending, (state) => {
        state.recentLoading = true
      })
      .addCase(getRecommendedClasses.rejected, (state) => {
        state.recentLoading = false
      })

      // GET CLASS CONTENT AND LESSONS
      .addCase(getClassContentLessons.fulfilled, (state, { payload }) => {
        state.loading = false
        state.classDetail = payload.data
        state.classInfo = payload.classInfo
        state.classTasks = payload.tasks
      })
      .addCase(getClassContentLessons.pending, (state) => {
        state.loading = true
      })
      .addCase(getClassContentLessons.rejected, (state) => {
        state.loading = false
      })
      // GET CATEGORY CONTENT
      .addCase(getCategoryContent.fulfilled, (state, { payload }) => {
        state.loading = false
        state.categoryDetail = payload.detail ?? ''
        state.categoryContents = payload.data
      })
      .addCase(getCategoryContent.pending, (state) => {
        state.loading = true
      })
      .addCase(getCategoryContent.rejected, (state) => {
        state.loading = false
      })
      // GET LESSON CONTENT
      .addCase(getLessonContent.fulfilled, (state, { payload }) => {
        state.loading = false
        state.lessonContent = payload.data
        state.lessonDetail = payload?.lessonDetail
        state.lessonTasks = payload.tasks
      })
      .addCase(getLessonContent.pending, (state) => {
        state.loading = true
      })
      .addCase(getLessonContent.rejected, (state) => {
        state.loading = false
      })
      .addCase(getContentDetail.fulfilled, (state, { payload }) => {
        state.contentDetail = payload.data
      })
      .addCase(setContentDetail.fulfilled, (state, { payload }) => {
        state.contentDetail = payload.data
      })
      .addCase(getCompleteLessonContent.fulfilled, (state, { payload }) => {
        state.frontCompleteLessonContent = payload?.data
        state.frontCompleteContentLoader = false
      })
      .addCase(getCompleteLessonContent.pending, (state) => {
        state.frontCompleteContentLoader = true
      })
      .addCase(getCompleteLessonContent.rejected, (state) => {
        state.frontCompleteContentLoader = false
      })
      .addCase(completeTasks.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Tasks has been Completed Successfully!')
        }
      })
      .addCase(getLessonsTasks.fulfilled, (state, { payload }) => {
        state.frontLessonsTasks = payload?.data
        state.nextLessonId = payload?.id
        state.lessonTasksLoading = false
      })
      .addCase(getLessonsTasks.pending, (state) => {
        state.lessonTasksLoading = true
      })
      .addCase(getLessonsTasks.rejected, (state) => {
        state.lessonTasksLoading = false
      })
      .addCase(setFrontLessonsTasks.fulfilled, (state, { payload }) => {
        state.frontLessonsTasks = payload?.data
      })
      .addCase(setLessonContents.fulfilled, (state, { payload }) => {
        console.log('---------lessonContents----fulfilled----', payload?.data)
        state.lessonContents = payload?.data
      })
      .addCase(setClassDetail.fulfilled, (state, { payload }) => {
        state.classLessonDetail = payload?.data
      })
      .addCase(joinAClass.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Class has been Joined Successfully!')
        }
      })
      // SET MEDITATION INDEX
      .addCase(setMeditationIndex.fulfilled, (state, { payload }) => {
        console.log('----setMeditationIndex---payload-', payload?.data)
        state.meditationIndex = payload?.data
      })
      //UPDATE WATCH TIME
      .addCase(updateWatchTime.fulfilled, (state, { payload }) => {
        console.log('--------updateWatchTime---fulfilled----', payload?.data)
      })
      .addCase(getVideoWatchTime.fulfilled, (state, { payload }) => {
        state.videoWatchedTime = payload?.data
      })
      .addCase(setGoBackToggle.fulfilled, (state, { payload }) => {
        state.toggleValue = payload?.data?.data
        state.lessonID = payload?.data?.id
      })
       // GET ALL CLASSES
       .addCase(getAllSharedClasses.pending, () => {
        console.log('Classes pending')
      })
      .addCase(getAllSharedClasses.rejected, () => {
       console.log('Classes rejected')
      })
      .addCase(getAllSharedClasses.fulfilled, (state, { payload }) => {
        state.allSharedClasses = payload?.data
      })
      .addCase(postquestions.pending, () => {
       console.log('pending questions')
      })
      .addCase(postquestions.fulfilled, (state, action) => {
        state.questions = action.payload.data
        displaySuccessToast('Your answers submitted successfully')
      })
      .addCase(postquestions.rejected, () => {
        console.log('rejected', postquestions.rejected)
      })

  }
})

export default newDiscoverySlice.reducer
